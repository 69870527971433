
















import Vue from "vue";
import rootStore from "@/store";
import { HaeQuestion, HaeState } from "@/types/hae";
const state = rootStore.state as { hae: HaeState };

// vuex-persistedstateがlocal storageからstateを取り出す時にundefinedをnullにしてしまう。
// 具体的にはブラウザを更新すると発生するのでこれを避けるためにnullをundefinedに差し替える
// (gettersでやるべきだが愚直に書いたら反映が遅延したので暫定対応)
function screeningAnswers(): Array<number | number[] | undefined> {
  return state.hae.screeningAnswers.map(el => (el === null ? undefined : el));
}

export default Vue.extend({
  name: "ScreeningRadio",
  data() {
    return {
      radio: screeningAnswers()[Number(this.$props.question.id) - 1]
    };
  },
  props: { question: Object as Vue.PropType<HaeQuestion> },
  watch: {
    radio: function(newValue, oldValue) {
      rootStore.commit("hae/changeScreeningAnswerValue", {
        id: Number(this.question.id),
        value: newValue
      });
    }
  },
  methods: {
    fetchChoiceClassName(idx: number): string {
      return [
        this.question.choices.length >= 3 ? "-many" : "choice-few",
        this.radio === idx ? "checked" : ""
      ].join(" ");
    }
  },
  computed: {}
});
