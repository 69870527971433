




















import Vue from "vue";
import { HaeQuestion } from "@/types/hae";
import ScreeningCheckBox from "@/components/hae/ScreeningCheckBox.vue";
import ScreeningRadio from "@/components/hae/ScreeningRadio.vue";

export default Vue.extend({
  name: "ScreeningSelect",
  props: { question: Object as Vue.PropType<HaeQuestion> },
  methods: {},
  computed: {},
  components: { ScreeningCheckBox, ScreeningRadio }
});
