







































import Vue from "vue";
import rootStore from "@/store";
import { HaeQuestion, HaeState } from "@/types/hae";
const state = rootStore.state as { hae: HaeState };

// vuex-persistedstateがlocal storageからstateを取り出す時にundefinedをnullにしてしまう。
// 具体的にはブラウザを更新すると発生するのでこれを避けるためにnullをundefinedに差し替える
// (gettersでやるべきだが愚直に書いたら反映が遅延したので暫定対応)
function screeningAnswers(): Array<number | number[] | undefined> {
  return state.hae.screeningAnswers.map(el => (el === null ? undefined : el));
}

export default Vue.extend({
  name: "ScreeningCheckBox",
  data() {
    return {
      checks: screeningAnswers()[Number(this.$props.question.id) - 1] || []
    };
  },
  props: { question: Object as Vue.PropType<HaeQuestion> },
  watch: {
    checks: function(newValue, _oldValue) {
      rootStore.commit("hae/changeScreeningAnswerValue", {
        id: Number(this.question.id),
        value: newValue
      });
    }
  },
  methods: {
    isEnable(idx: number): boolean {
      // checksはnumber[]にしかならないが、仮になったとしたら全部disableでいい
      if (typeof this.checks === "number") {
        return false;
      }

      // 選択なしならすべてenable
      if (this.checks.length === 0) {
        return true;
      }

      // exを選択中ならex以外をdisble
      if (this.question.choices.length <= Math.max(...this.checks)) {
        return idx >= this.question.choices.length;
      } else {
        // ex以外を選択中ならexをdisable
        return idx < this.question.choices.length;
      }
    }
  },
  computed: {}
});
