








export default {
  name: "ScreeningHeadline"
};
